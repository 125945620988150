import { useStaticQuery, graphql } from 'gatsby';

const getRandom = (array, count) => {
  return array.sort(() => .5 - Math.random()).slice(0, count);
}

export const useRandomBrandHeaderImages = (count = 1, type = 'all', lowestBrandRating = 1) => {
  let brandsValid = false;
  let randomBrands = [];
  let brands = useStaticQuery(query).allDirectusBrands.nodes;
  if (type !== 'all') {
    brands = brands.filter(({ brand }) => brand.type.type === type);
  }
  if (lowestBrandRating > 1) {
    brands = brands.filter(({ brand }) => brand.brand_rating >= lowestBrandRating);
  }

  while (!brandsValid) {
    const validity = [];
    randomBrands = getRandom(brands, count);
    randomBrands.forEach(({ brand }) => {
      validity.push(brand.header_images.length !== 0);
    })
    brandsValid = !validity.includes(false);
  }

  return randomBrands;
};

const query = graphql`
  query {
    allDirectusBrands(filter: {brand: {status: {eq: "published"}}, id: {ne: "dummy"}}) {
      nodes {
        brand {
          display_name
          slug
          brand_rating
          type {
            type
          }
          header_images {
            file {
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 1980, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;