import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Item = styled.div`
  width: ${props => 100 / props.colCount - 5}%;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 640px) {
    width: 100% !important;
  }
  ${props => props.colCount === 4 ? css`
    @media (max-width: 960px) {
      width: 48%;
    }` : null};
  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 0.9rem;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;
