import React, { useContext, useState } from 'react';
import PropTypes                       from 'prop-types';
import Img                             from 'gatsby-image';
import { Breakpoint }                  from 'react-socks';
import ReactTooltip                    from 'react-tooltip';

import Carousel            from '@interness/web-core/src/components/media/Carousel/Carousel';
import ConditionalWrapper  from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import Link                from '@interness/web-core/src/components/elements/Link/Link';
import routes              from '@interness/web-core/config/routes/index';
import { LanguageContext } from '@interness/web-core/src/providers';

import { useRandomBrandHeaderImages } from '../../../hooks/useRandomBrandHeaderImages';

const RandomBrandHeaderImages = (props) => {
  const [randomBrands, setRandomBrands] = useState();

  const { language } = useContext(LanguageContext);

  if (!randomBrands) {
    const random = useRandomBrandHeaderImages(props.count, props.type, props.lowestBrandRating);
    setRandomBrands(random);
    return null;
  } else {
    return (
      <Carousel>
        {randomBrands.map(({ brand }) => {
          const image = brand.header_images.filter(image => image.file.localFile.childImageSharp.fluid.aspectRatio !== 1)[0];
          const brandLink = `${routes[brand.type.type][language]}/${brand.slug}`
          if (!image) {
            console.log('Header image missing for brand: ', brand.display_name);
          }
          return (
            <ConditionalWrapper key={image.file.localFile.name} condition={props.linkImage}
                                wrapper={(children) => {
                                  return (
                                    <>
                                      <Link to={brandLink} data-tip={brand.display_name}>{children}</Link>
                                      <Breakpoint lage up>
                                        <ReactTooltip delayShow={200}/>
                                      </Breakpoint>
                                    </>
                                  );
                                }}>
              <Img
                fluid={image.file.localFile.childImageSharp.fluid} alt={brand.display_name} loading="eager"/>
            </ConditionalWrapper>
          );
        })}
      </Carousel>
    )
  }

};

RandomBrandHeaderImages.propTypes = {
  count: PropTypes.number,
  type: PropTypes.string,
  lowestBrandRating: PropTypes.number,
  linkImage: PropTypes.bool,
};

RandomBrandHeaderImages.defaultProps = {
  count: 1,
  type: 'all',
  lowestBrandRating: 1,
  linkImage: true,
};

export default RandomBrandHeaderImages;
