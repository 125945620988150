import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import Img                   from 'gatsby-image';

import { findT }                     from '@interness/web-core/src/components/_helpers';
import Button                        from '@interness/web-core/src/components/elements/Button/Button';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import useDisplayBrands              from '@interness/brands-addon/src/hooks/useDisplayBrands';
import routes                        from '@interness/web-core/config/routes';
import { LanguageContext }     from '@interness/web-core/src/providers';
import { getRandomBrandImage } from '@interness/brands-addon/src/components/_helpers';

import * as S from './styles';

const Display = ({ exclude, order }) => {
  const { displayTypes, brands } = useDisplayBrands(exclude, order);
  const { language } = useContext(LanguageContext);
  return (
    <S.Container>
      {displayTypes.map(displayType => {
        const translations = findT(displayType.translations, 'de');
        const brand = getRandomBrandImage(brands.nodes, displayType.type);
        return (
          <S.Item colCount={displayTypes.length} key={displayType.type}>
            <div>
              <Img fluid={brand.header_images[0].file.localFile.childImageSharp.fluid} alt={brand.display_name}/>
              <h4>{translations.display_name}</h4>
              <h5>{translations.subtitle}</h5>
              <p dangerouslySetInnerHTML={{ __html: translations.short_description }}/>
            </div>
            <Button as={Link} to={routes[displayType.type][language]}>Mehr erfahren</Button>
          </S.Item>
        )
      })}
    </S.Container>
  )
};

Display.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.arrayOf(PropTypes.string)
};

Display.defaultProps = {
  exclude: [''],
  order: ['']
};

export default Display;
