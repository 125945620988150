import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Col, Row } from 'react-grid-system';

import CallToAction            from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading                 from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                 from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Display                 from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements       from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import ResponsiveIFrame        from '@interness/web-core/src/components/structure/iFrame/iFrame';

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Weissgerber</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Juwelier Weissgerber, im Herzen der schönen Stadt Hagen-Hohenlimburg gelegen, ist seit 1952, nun mehr in der zweiten Generation, ein Begriff für außergewöhnlichen Schmuck und hervorragende Uhren und traumhaften Trauringen und Eheringen.</p>
            <p> In unseren modernen Geschäftsräumen möchten wir Ihnen ein noch schöneres Einkaufserlebnis ermöglichen. Als Uhrmachermeister überzeugen mit bestem Service. Freundliche und fachlich kompetente Beratung sind für uns eine Selbstverständlichkeit.</p> 
            <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.</p>

            <p>BIS BALD IN HOHENLIMBURG<br/>
IHR TEAM VON JUWELIER WEISSGERBER</p>
          </span>
        </section>

        <section>
          <ResponsiveIFrame src={'https://player.vimeo.com/video/818391417?h=db73ab8794&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} type={'vimeo'} />
        </section>
    
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser virtuelles Schaufenster</Heading>
          <Display/>
          <Spacer/>
        </section>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich.
            Neuwertermittlung: Bewertung von Schmuckstücken und Aufarbeitung</p>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h3>Armbanduhren</h3>
                <List>
                  <li>mechanische Uhren / Handaufzug</li>
                  <li>Quarzarmbanduhren</li>
                  <li>Automatikuhren</li>
                  <li>Batteriewechsel</li>
                  <li>Komplette Revisionen</li>
                  <li>Taschenuhren</li>
                  <li>Glasersatz</li>
                  <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                  <li>Bandanpassung</li>
                </List>
              </Col>
              <Col md={6}>
                <h3>Goldschmiedereparaturen</h3>
                <List>
                  <li>Goldschmiedereparaturen aller Art</li>
                  <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                  <li>Ringgrößen ändern</li>
                  <li>Perlenketten neu fassen oder knoten</li>
                  <li>Trauringumarbeitung</li>
                  <li>Neuanfertigungen</li>
                  <li>Schmuck reinigen und aufarbeiten</li>
                  <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                </List>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <h3>Großuhren</h3>
                <List>
                  <li>Heim – und Tischuhren, Jahresuhren</li>
                  <li>Standuhren</li>
                  <li>Regulatoren</li>
                </List>
              </Col>
              <Col md={6}>
                <h3>Altgoldankauf</h3>
                <List>
                  <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                  <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken.</li>
                  <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen.</li>
                  <li>Altgoldankauf ist Vertrauenssache</li>
                </List>
              </Col>
            </Row>
          </Container>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
