import { graphql, useStaticQuery } from 'gatsby';

const orderTypes = (types, order) => {
  types.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
  return types
};

const useDisplayBrands = (exclude, order) => {
  const { brands, brand_types } = useStaticQuery(query);
  const usedTypes = [];
  brands.nodes.forEach(({ brand }) => {
    if (!usedTypes.includes(brand.type.type) && !exclude.includes(brand.type.type)) {
      usedTypes.push(brand.type.type)
    }
  });
  return {
    displayTypes: orderTypes(brand_types.nodes.filter(brand_type => usedTypes.includes(brand_type.type)), order),
    brands: brands
  }
};

export default useDisplayBrands;

const query = graphql`
  query {
    brands: allDirectusBrands(filter: {brand: {status: {eq: "published"}}, id: {ne: "dummy"}}) {
      nodes {
        brand {
          display_name
          type {
            type
          }
          header_images {
            file {
              width
              height
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 450, maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          gallery_images_square {
              file {
                  width
                  height
                  localFile {
                      name
                      childImageSharp {
                          fluid(maxWidth: 450, maxHeight: 450, quality: 100) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
        }
      }
    }
    brand_types: allDirectusBrandTypes(filter: {id: {ne: "dummy"}}) {
      nodes {
        type
        translations {
          display_name
          language
          short_description
          subtitle
          description
        }
      }
    }
  }
`;